<template>
  <div class="chat py-3">
    <component
      v-if="getElement(item)"
      :ref="`message-${item.id}`"
      v-for="item in chat"
      :key="item.id"
      :is="getElement(item)"
      :result="result.find((r) => r.message_id == item.id)"
      :status="status"
      :data="item"
      @next="onNext(item)"
      @restart="onRestart(item, $event)"
      @complete="onComplete(item, $event)"
      @goto="onGoto(item, $event)"
    />
  </div>
</template>

<script>
import "./../components";
import { getAccess } from "@/components/mixings";
export default {
  mixins: [getAccess],
  components: {},
  props: {
    chat: { type: Array, default: () => [] },
    id: Number,
    lesson: { type: Object, default: () => {} },
    data: Object,
  },
  data() {
    return {
      loaded: false,
      types: { 1: "text", 2: "media", 3: "question", 4: "testing" },
      result: [],
    };
  },
  created() {
    this.initData();
  },
  computed: {
    status() {
      return this.lesson?.status;
    },
  },
  methods: {
    getElement(item) {
      if (!item?.type) return false;
      return `education-message-${this.types[item?.type]}`;
    },
    async restart() {
      this.$emit("restart");
    },

    async onNext(d) {
      let res = { message_id: d.id, status: "done", next_id: d.next_id };
      this.result.push(res);
      this.$emit("next", { item: d, message: res, next_id: d.next_id });
    },
    async onGoto(d, { answer, next_id }) {
      let res = { message_id: d.id, status: "done", answer };
      this.result.push(res);
      this.$emit("goto", { item: d, message: res, next_id });
    },
    async onRestart(d, a) {
      let res = { message_id: d.id, status: "test", result: a };
      this.result.push(res);
      this.$emit("restart", { message: res });
    },
    async onComplete(d, a) {
      let res = { message_id: d.id, status: "test", result: a };
      this.result.push(res);
      this.$emit("complete", { message: res });
    },
    scrollTo(id) {
      this.$nextTick(() => {
        if (!this.$refs[`message-${id}`]) return;
        const chatBlock = this.$refs.chat_block;
        const newMessageElement = this.$refs[`message-${id}`][0];
        if (chatBlock && newMessageElement) {
          const chatBlockHeight = chatBlock.clientHeight;
          const newMessagePosition = newMessageElement.$el.offsetTop;
          const offset = chatBlockHeight;
          chatBlock.scrollTop = newMessagePosition;
        }
      });
    },
    async initData() {
      console.log("init lesson result", this.lesson);
      this.result = [...(this.lesson?.messages || [])];
      await this.fitchData();
    },
    async fitchData() {
      try {
      } catch (error) {
        this.$root.$emit("show-info", { type: "error", text: "Ошибка загрузки данных" });
      }
    },
  },
};
</script>
